import styled from 'styled-components';

interface IScImageTextCardItem {
	background?: string;
	isLargeImage?: boolean;
}

export const ScImageTextCardItem = styled.div<IScImageTextCardItem>`
	display: flex;
	flex-direction: column;
	gap: 55px;
	border-radius: 10px;
	padding: 20px;
	box-sizing: border-box;
	background: ${({ theme, background }) => background || theme.colors.toasted};
	height: 100%;
	position: relative;
	min-height: 310px;
	img {
		height: ${({ isLargeImage }) => (isLargeImage ? '180px' : '130px')};
		width: auto;
		max-width: max-content;
		margin: 0 auto;
		position: ${({ isLargeImage }) => (isLargeImage ? 'initial' : 'absolute')};
		right: 20px;
		bottom: 15px;
	}
	@media (min-width: 768px) {
		padding: 46px 35px;
		border-radius: 30px;
		img {
			height: 275px;
			width: auto;
			margin: 0 auto;
			position: inherit;
		}
	}
	@media (min-width: 1220px) {
		padding: 48px 30px 35px 30px;
		border-radius: 30px;
		gap: 25px;
		img {
			height: 232px;
			width: auto;
			margin: 0 auto;
			position: inherit;
		}
	}
	@media (min-width: 1440px) {
		gap: 50px;
	}
	@media (min-width: 1920px) {
		padding: 46px 35px;
		border-radius: 30px;
		img {
			height: 275px;
			width: auto;
			margin: 0 auto;
			position: inherit;
		}
	}
`;

interface IScImageTextCardItemTextWrapper {
	color?: string;
	isLargeImage?: boolean;
}

export const ScImageTextCardItemTextWrapper = styled.div<IScImageTextCardItemTextWrapper>`
	display: flex;
	flex-direction: column;
	gap: 24px;
	h6,
	p {
		text-align: ${({ isLargeImage }) => (isLargeImage ? 'center' : 'left')};
	}
	h6 {
		color: ${({ theme, color }) => color || theme.colors.strongWhite};
		font: ${({ theme }) => theme.fonts.heading.small};
	}
	p {
		color: ${({ theme, color }) => color || theme.colors.strongWhite};
		font: ${({ theme }) => theme.fonts.paragraph.small};
		width: ${({ isLargeImage }) => (isLargeImage ? '260px' : '175px')};
		margin: ${({ isLargeImage }) => (isLargeImage ? '0 auto' : 0)};
	}
	@media (min-width: 768px) {
		gap: 40px;
		justify-content: center;
		h6,
		p {
			text-align: center;
		}
		h6 {
			font: ${({ theme }) => theme.fonts.heading.card};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.xl};
			width: 100%;
		}
	}
	@media (min-width: 768px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.medium};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.large};
			width: 100%;
		}
	}
	@media (min-width: 1220px) {
		gap: 25px;
		h6 {
			font: ${({ theme }) => theme.fonts.heading.mediumXl};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.enormous};
			width: 100%;
		}
	}
	@media (min-width: 1440px) {
		gap: 25px;
		margin-left: 15px;
		margin-right: 15px;
		h6 {
			font: ${({ theme }) => theme.fonts.heading.medium};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.large};
			width: 100%;
		}
	}
	@media (min-width: 1920px) {
		margin: 0;
		h6 {
			font: ${({ theme }) => theme.fonts.heading.card};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.xl};
			max-width: 519px;
		}
	}
`;
