import React from 'react';
import { useTheme } from 'styled-components';

import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import image from '../../assets/images/employee-nursery-hero.png';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import {
	ScEmployeeCyclingHeroContainer,
	ScEmployeeCyclingHeroWrapper,
	ScHeroContainer,
} from '../EmployeeCycling/styled';

import { ScEmployeeNurseryHeroLeft, ScEmployeeNurseryHeroRight } from './styled';

export const EmployeeNurseryHero = () => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();

	return (
		<ScEmployeeCyclingHeroContainer>
			<ScTop>
				<ScHeroContainer
					ref={ref}
					initial="hidden"
					animate={controls}
					variants={animationContainer}
				>
					<ScContainer>
						<ScEmployeeCyclingHeroWrapper>
							<ScEmployeeNurseryHeroLeft variants={fadeInRight}>
								<img src={image} alt="bread" />
							</ScEmployeeNurseryHeroLeft>
							<ScEmployeeNurseryHeroRight variants={fadeInLeft}>
								<h3>Want to save on nursery fees?</h3>
								<ScParagraph color={theme.colors.strongWhite} centered={!isDesktop}>
									We can help. Gogeta Nursery lets you make big savings on your nursery costs by
									paying for them out of your gross salary.
								</ScParagraph>
							</ScEmployeeNurseryHeroRight>
						</ScEmployeeCyclingHeroWrapper>
					</ScContainer>
				</ScHeroContainer>
			</ScTop>
		</ScEmployeeCyclingHeroContainer>
	);
};
