import { useEffect, useState } from 'react';

import { isNotSSR } from '@utils';

interface WindowDimensions {
	width: number;
	height: number;
}

const useWindowDimensions = (): WindowDimensions => {
	const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
		width: isNotSSR && window.innerWidth,
		height: isNotSSR && window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			if (isNotSSR) {
				setWindowDimensions({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return windowDimensions;
};

export default useWindowDimensions;
