import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import {
	animationContainer,
	cardUp,
	fadeInLeft,
	fadeInRight,
	fadeInUp,
} from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { IImageTextCardItem, ImageTextCard } from '../../components/ImageTextCard';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import useWindowDimensions from '../../hooks/use-window-width';
import { ScHowItWorksItemsWrapper } from '../EmployeeCycling/styled';
import { ScTestimonialsWrapper } from '../Home/styled';

export const HowItWorks = ({ items }: { items: IImageTextCardItem[] }) => {
	const { ref, controls } = useContentAnimation();
	const { width } = useWindowDimensions();
	const theme = useTheme();

	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScTestimonialsWrapper>
					<MotionDiv variants={fadeInUp}>
						<ScTitle centered>How it works</ScTitle>
					</MotionDiv>
					<ScHowItWorksItemsWrapper ref={ref}>
						{items.map((item, index) => (
							<MotionDiv
								key={item.title}
								variants={width >= 1220 ? cardUp : index % 2 === 0 ? fadeInLeft : fadeInRight}
							>
								<ImageTextCard
									item={item}
									background={theme.colors.peach}
									color={theme.colors.darkText}
								/>
							</MotionDiv>
						))}
					</ScHowItWorksItemsWrapper>
				</ScTestimonialsWrapper>
			</motion.section>
		</ScContainer>
	);
};
