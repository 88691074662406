import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ScEmployeeNurseryHeroLeft = styled(motion.div)`
	padding: 20px;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.blueberry};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	min-height: 35svh;
	img {
		width: 100%;
		height: auto;
	}
	@media (min-width: 768px) {
		min-height: 50svh;
	}
	@media (min-width: 1024px) {
		height: 100%;
		width: 100%;
	}
	@media (min-width: 1220px) {
		padding: 95px 25px;
		border-radius: 30px;
		height: 100%;
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (min-height: 500px) and (max-height: 900px) {
		padding: 15px 50px;
		img {
			width: 80%;
		}
	}
`;

export const ScEmployeeNurseryHeroRight = styled(motion.div)`
	padding: 25px 45px;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.marmelade};
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	border-radius: 10px;
	button {
		margin-top: auto;
	}
	width: 100%;
	height: 100%;
	h3 {
		color: ${({ theme }) => theme.colors.strongWhite};
		text-align: center;
		margin: 0;
		font: ${({ theme }) => theme.fonts.heading.small};
		@media (max-width: 767px) and (min-width: 390px) and (min-height: 700px) {
			font: ${({ theme }) => theme.fonts.heading.mediumXl};
		}
		@media (min-width: 1024px) {
			font: ${({ theme }) => theme.fonts.hero.medium};
		}
		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.largeHeading.medium};
			text-align: left;
		}
		@media (min-width: 1220px) and (max-width: 1919px) and (min-height: 500px) and (max-height: 900px) {
			font: ${({ theme }) => theme.fonts.largeHeading.largeSmall};
		}
	}
	@media (min-width: 1024px) {
		height: 100%;
		width: 100%;
		button {
			margin-top: auto;
		}
	}
	@media (min-width: 1220px) {
		padding: 32px 42px 54px 42px;
		border-radius: 30px;
		height: 100%;
		gap: 45px;
		align-items: start;
		button {
			margin-top: auto;
		}
		> div {
			max-width: 400px;
		}
	}
	@media (min-width: 1920px) {
		gap: 64px;
		> div {
			max-width: 550px;
		}
		button {
			margin-top: auto;
		}
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (min-height: 500px) and (max-height: 900px) {
		padding: 32px;
		gap: 20px;
	}
`;
